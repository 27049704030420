import logo72x72 from "./logo72x72.png";
import logo96x96 from "./logo72x72.png";
import logo192x192 from "./logo192x192.png";
import logo138x75black from "./logo138x75black.png";
import logo138x75white from "./logo138x75white.png";
import logo165x90white from "./logo165x90white.png";
import logo1024x1024 from "./logo1024x1024.png";

export default {
  logo72x72,
  logo96x96,
  logo192x192,
  logo138x75black,
  logo138x75white,
  logo1024x1024,
  logo165x90white,
};
