/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { useHistory } from "react-router-dom";
import { language } from "config";
import { useSelector } from "react-redux";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  let history = useHistory();
  return <footer className={footerClasses}></footer>;
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
