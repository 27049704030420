import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import {
  Paper,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import ProductSection from "./Sections/ProductSection.js";
import SectionDownload from "./Sections/SectionDownload.js";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlertDialog from "../components/AlertDialog";
import { language } from "config";
import { FirebaseContext } from "common";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
  } = api;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ...rest } = props;
  const cartypes = useSelector((state) => state.cartypes.cars);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [carType, setCarType] = useState(language.select_car);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = React.useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [bookingType, setBookingType] = useState("Book Now");
  const [role, setRole] = useState(null);
  const [selectedDate, setSelectedDate] = useState(GetDateString());

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
      }
    }
    setSelectedCarDetails(carDetails);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    if (bookingType === "Book Later") {
      setSelectedDate(GetDateString());
    }
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate) {
      setEstimateModalStatus(true);
    }
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [estimatedata.estimate, auth.info]);

  const handleGetEstimate = (e) => {
    e.preventDefault();
    if (auth.info) {
      if (pickupAddress && dropAddress && selectedCarDetails) {
        const directionService = new window.google.maps.DirectionsService();
        directionService.route(
          {
            origin: new window.google.maps.LatLng(
              pickupAddress.coords.lat,
              pickupAddress.coords.lng
            ),
            destination: new window.google.maps.LatLng(
              dropAddress.coords.lat,
              dropAddress.coords.lng
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              const route = {
                distance: result.routes[0].legs[0].distance.value / 1000,
                duration: result.routes[0].legs[0].duration.value,
                polylinePoints: result.routes[0].overview_polyline,
              };
              if (bookingType === "Book Now") {
                dispatch(
                  getEstimate({
                    pickup: pickupAddress,
                    drop: dropAddress,
                    carDetails: selectedCarDetails,
                    routeDetails: route,
                  })
                );
              } else {
                if (bookingType === "Book Later" && selectedDate) {
                  if (MinutesPassed(selectedDate) >= 15) {
                    dispatch(
                      getEstimate({
                        pickup: pickupAddress,
                        drop: dropAddress,
                        carDetails: selectedCarDetails,
                        routeDetails: route,
                      })
                    );
                  } else {
                    setCommonAlert({
                      open: true,
                      msg: language.past_booking_error,
                    });
                  }
                } else {
                  setCommonAlert({ open: true, msg: language.select_proper });
                }
              }
            } else {
              setCommonAlert({
                open: true,
                msg: language.place_to_coords_error,
              });
            }
          }
        );
      } else {
        setCommonAlert({ open: true, msg: language.select_proper });
      }
    } else {
      setCommonAlert({ open: true, msg: language.must_login });
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(
      addBooking({
        pickup: pickupAddress,
        drop: dropAddress,
        carDetails: selectedCarDetails,
        userDetails: auth.info,
        estimate: estimatedata.estimate,
        tripdate: new Date(selectedDate).toString(),
        bookLater: bookingType === "Book Later" ? true : false,
        settings: settings,
        booking_type_web: true,
      })
    );
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    props.history.push("/bookings");
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/background.jpg").default}>
        {(cartypes && !role) ||
        (cartypes && (role === "rider" || role === "admin")) ? (
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <br />
                <h1 className={classes.title}>Nioudem Dashboard</h1>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <br />
                <h1 className={classes.title}>{language.landing_slogan}</h1>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </Parallax>

      <Footer />
      <Dialog
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.estimate}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {settings ? settings.symbol : null}{" "}
            {estimatedata.estimate ? estimatedata.estimate.estimateFare : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEstimateModalClose} color="primary">
            {language.cancel}
          </Button>
          <Button onClick={confirmBooking} color="primary" autoFocus>
            {language.book}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={bookingdata.booking ? true : false}
        onClose={handleBookingAlertClose}
      >
        {bookingdata.booking
          ? language.booking_success + bookingdata.booking.booking_id
          : null}
      </AlertDialog>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
