export const MainConfig = {
  AppDetails: {
    app_name: "Niou Dem Driver",
    app_description: "Application de taxi pour les chauffeurs.",
    app_identifier: "com.nioudem.driver",
    ios_app_version: "6.9.1",
    android_app_version: 120,
  },
  FirebaseConfig: {
    apiKey: "AIzaSyBtRo3t8-2Lr2ab6bY7OAw9nJJP_Aan9tA",
    authDomain: "nioudem-904b7.firebaseapp.com",
    databaseURL: "https://nioudem-904b7-default-rtdb.firebaseio.com",
    projectId: "nioudem-904b7",
    storageBucket: "nioudem-904b7.appspot.com",
    messagingSenderId: "49081244385",
    appId: "1:49081244385:web:14687979f57c932579b5a0",
    measurementId: "G-N05K3KMD5J",
  },
  Google_Map_API_Key: {
    ios: "AIzaSyBNLlbzud6xDSZjHb0A7T60_7vyV5ZFs3o",
    android: "AIzaSyBNLlbzud6xDSZjHb0A7T60_7vyV5ZFs3o",
    web: "AIzaSyBNLlbzud6xDSZjHb0A7T60_7vyV5ZFs3o",
  },
  facebookAppId: "238855978289148",
};
